import { ModalRef, ModalService } from '@app/custom/features/modal';
import {
  BREAKPOINT,
  BreakpointService,
  CurrentProductService,
} from '@spartacus/storefront';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import SwiperCore, {
  FreeMode,
  Navigation,
  Pagination,
  Swiper,
  SwiperOptions,
  Thumbs,
} from 'swiper';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Product } from '@spartacus/core';
import { RrsProductDetailsZoomComponent } from '@app/custom/features/rrs-product-details/components';
import { SwiperComponent } from 'swiper/angular/swiper-angular';
import { Scene7Presets } from '@app/shared/configs/scene7.config';

SwiperCore.use([FreeMode, Navigation, Pagination, Thumbs]);

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-product-details-images',
  styleUrls: ['./rrs-product-details-images.component.scss'],
  templateUrl: './rrs-product-details-images.component.html',
})
export class RrsProductDetailsImagesComponent {
  isDesktop$ = this.breakpointService.isUp(BREAKPOINT.lg);

  scene7Preset = Scene7Presets.ALT_MAIN;

  product$: Observable<Product | null> =
    this.currentProductService.getProduct();

  productGallery$ = this.product$.pipe(
    map((product) => this.createGallery(product || {})),
    // swiper needs to update after DOM changes
    tap(() => {
      setTimeout(() => {
        this.swiper?.swiperRef?.update();
      }, 0);
    })
  );

  activeSlide: number = 0;

  configMain: SwiperOptions = {
    breakpoints: {
      992: {
        pagination: false,
      },
    },
    navigation: false,
    pagination: true,
    slidesPerGroup: 1,
    slidesPerView: 1,
    threshold: 10,
  };

  configTumbs: SwiperOptions = {
    freeMode: true,
    navigation: {
      nextEl: '.swiper-arrow-next',
      prevEl: '.swiper-arrow-prev',
    },
    slidesPerGroup: 4,
    slidesPerView: 4,
    spaceBetween: 16,
    watchSlidesProgress: true,
    threshold: 10,
  };

  iconTypeList = ICON_TYPE_LIST;

  modalRef: ModalRef | undefined;

  thumbsSwiper: Swiper | null | undefined;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  constructor(
    protected breakpointService: BreakpointService,
    protected currentProductService: CurrentProductService,
    protected modalService: ModalService
  ) {}

  openModal(): void {
    this.modalRef = this.modalService.open(RrsProductDetailsZoomComponent, {
      size: 'fullscreen',
      windowClass: 'product-details-zoom-modal',
    });

    const modalInstance = this.modalRef.componentInstance;
    modalInstance.activeSlide = this.activeSlide;
    modalInstance.product$ = this.product$;
    modalInstance.productGallery$ = this.productGallery$;
  }

  onSlideChange(): void {
    this.activeSlide = this.swiper?.swiperRef?.activeIndex || 0;
  }

  protected createGallery(product: Product): any[] {
    if (!product?.images?.GALLERY) return [];

    return (product.images.GALLERY as any[]).map((c) => {
      const container = {
        zoom: {
          ...c.zoom,
          url: `${c.zoom.url}&${Scene7Presets.SMALL_PDP}`,
        },
      };
      return { container };
    });
  }
}
