import { RrsCheckoutStepsParams } from '@app/custom/features/rrs-cms/configs/contentstack.config';
import { Transaction } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/model/checkout.model';
import {
  Cart,
  CartAddEntryEvent,
  CartAddEntryFailEvent,
  CartAddEntrySuccessEvent,
  OrderEntry,
} from '@spartacus/cart/base/root';
import { CxEvent, Product } from '@spartacus/core';

export class RrsEvent extends CxEvent {
  customEvent?: CustomEvent = undefined;
  satelliteEvent?: string = undefined;
}

export interface RrsUserInfo {
  authStatus: 'registered' | 'guest';
  email?: string;
  phone?: string;
  loyaltyId?: string;
  firstName?: string;
  lastName?: string;
}

export interface RrsSearchResultProducts {
  code?: string;
  price: number;
  originalPrice: number;
}

export interface RrsPageInfo {
  pageInfo: {
    pageName: string;
    pageLoadEvent?: string;
    searchKeywords?: string;
    searchResultCount?: number;
    searchResultProducts?: RrsSearchResultProducts[];
    searchEvent?: string;
  };
  category: {
    pageType: string;
    primaryCategory?: string;
    subCategory1?: string;
    subCategory2?: string;
  };
}

export interface RrsProductInfo {
  productInfo: {
    sku: string;
    productBrand?: string;
    price?: string;
    quantity?: string;
  };
}

export interface CartEventInfo {
  email?: string;
  items: RrsProductInfo[];
  cart_id: string;
  promoCode: string;
}

export class RrsCartAddEntrySuccessEvent extends CartAddEntrySuccessEvent {
  price!: number;
  cart!: Cart;
}

export class RrsCartAddEntryEvent extends CartAddEntryEvent {
  price!: number;
  cart!: Cart;
}

export class RrsCartAddEntryFailEvent extends CartAddEntryFailEvent {
  price!: number;
  cart!: Cart;
}

export interface RrsEventInfo {
  eventInfo?: {
    eventName: string;
    eventAction: string;
    eventPage: string;
  };
  productInfo?: {
    addToCartLocaton?: string;
    sku: string;
    cart_id?: string;
    sizeSku?: string;
    quantity?: number;
    price?: number;
  };
  product?: {
    productInfo: {
      sku: string;
    };
  };
  userInfo?: RrsUserInfo;
  cart?: {
    cart_id: string;
  };
  page?: RrsPageInfo;
}

export interface RrsEventFormInfo {
  formInfo: {
    formAction: string;
    formName?: string;
    formFieldName?: string;
  };
}

export class RrsPageEvent extends RrsEvent {
  page!: RrsPageInfo;

  cart?: CartEventInfo;
  product?: RrsProductInfo;
  event?: RrsEventInfo[];
  form?: RrsEventFormInfo;
}

export class RrsCategoryPageEvent extends RrsPageEvent {
  static readonly type = 'RrsCategoryPageEvent';
}

export class RrsSearchPageEvent extends RrsPageEvent {
  static readonly type = 'RrsSearchPageEvent';
}

export class RrsCartPageEvent extends RrsPageEvent {
  static readonly type = 'RrsCartPageEvent';
}

export class RrsGenericPageEvent extends RrsPageEvent {
  static readonly type = 'RrsGenericPageEvnet';
}

export class RrsProductDetailsPageEvent extends RrsPageEvent {
  static readonly type = 'RrsProductDetailsPageEvent';
}

export class RrsCheckoutPageEvent extends RrsPageEvent {
  static readonly type = 'RrsCheckoutPageEvent';
}

export class RrsMyProfilePageEvent extends RrsPageEvent {
  static readonly type = 'RrsMyProfilePageEvent';
}

export class RrsRemoveEntryFromCartEvent extends RrsEvent {
  static readonly type = 'RrsRemoveEntryFromCartEvent';
  entry!: OrderEntry;
  cart!: Cart;
}

export class RrsCartRemoveEntrySuccessEvent extends RrsEvent {
  static readonly type = 'RrsCartRemoveEntrySuccessEvent';
  entry!: OrderEntry;
  cart!: CartEventInfo;
}

export class RrsAddToWishlistUiEvent extends RrsEvent {
  static readonly type = 'RrsAddToWishlistUiEvent';
  cartId!: string;
  product!: Product;
}

export class RrsCustomEvent extends RrsEvent {
  event!: RrsEventInfo[];
}

export class RrsAddToCartEvent extends RrsCustomEvent {
  static readonly type = 'RrsAddToCartEvent';
  satelliteEvent? = 'add-to-cart';
  cart!: Cart;
}

export class RrsAddToCartSuccessEvent extends RrsCustomEvent {
  static readonly type = 'RrsAddToCartSuccessEvent';
  satelliteEvent? = 'add-to-cart-success';
  cart!: CartEventInfo;
}

export class RrsAddToWishlistEvent extends RrsCustomEvent {
  static readonly type = 'RrsAddToWishlist';
  satelliteEvent? = 'add-to-wishlist';
}

export class RrsRemoveFromCartEvent extends RrsCustomEvent {
  static readonly type = 'RrsRemoveFromCartEvent';
  satelliteEvent? = 'remove-from-cart';
}

export class RrsLiveChatClickEvent extends RrsCustomEvent {
  static readonly type = 'RrsLiveChatClickEvent';
  satelliteEvent? = 'live-chat';
}

export class RrsAddNewShippingAddressEvent extends RrsCustomEvent {
  static readonly type = 'RrsAddNewShippingAddressEvent';
}

export class RrsEditShippingAddressEvent extends RrsCustomEvent {
  static readonly type = 'RrsEditShippingAddressEvent';
}

export class RrsEditAccountInfoEvent extends RrsCustomEvent {
  static readonly type = 'RrsEditAccountInfoEvent';
}

export class RrsSocialLinkClickEvent extends RrsCustomEvent {
  static readonly type = 'RrsSocialLinkClickEvent';
  satelliteEvent? = 'social-footer-links';
}

export class RrsInternalSearchClickEvent extends RrsCustomEvent {
  static readonly type = 'RrsInternalSearchClickEvent';
  satelliteEvent? = 'internal-search-clicks';
}

export class RrsExpressCheckoutEvent extends RrsCustomEvent {
  static readonly type = 'RrsExpressCheckoutEvent';
  satelliteEvent? = 'express-checkout';
}

export class RrsSortChangeEvent extends RrsCustomEvent {
  static readonly type = 'RrsSortChangeEvent';
  satelliteEvent? = 'product-sorting';
}

export class RrsFacetChangeEvent extends RrsCustomEvent {
  static readonly type = 'RrsFacetChangeEvent';
  satelliteEvent? = 'product-filters';
}

export class RrsLoginSuccessEvent extends RrsEvent {
  static readonly type = 'RrsLoginPageEvent';
  satelliteEvent? = 'login-success';
  form?: RrsEventFormInfo = undefined;
  user?: { userInfo: RrsUserInfo };
}

export class RrsRegistrationStartEvent extends RrsEvent {
  static readonly type = 'RrsRegistrationStartEvent';
  satelliteEvent? = 'registration-start';
  form?: RrsEventFormInfo | undefined;
}

export class RrsRegistrationSuccessEvent extends RrsEvent {
  static readonly type = 'RrsRegistrationSuccessEvent';
  satelliteEvent? = 'registration-success';
}

export class RrsOrderPlacedEvent extends RrsPageEvent {
  static readonly type = 'RrsOrderPlacedEvent';
  transaction!: Transaction;
}

export class RrsErrorNotificationEvent extends RrsEvent {
  static readonly type = 'RrsErrorNotificationEvent';
}

export class RrsEmailSignupSuccessEvent extends RrsEvent {
  static readonly type = 'RrsEmailSignupSuccessEvent';
  satelliteEvent? = 'email-signup-success';
}

export class RrsKlarnaPaymentEvent extends RrsEvent {
  static readonly type = 'RrsKlarnaPaymentEvent';
}

export class RrsForgotYourPasswordEvent extends RrsPageEvent {
  static readonly type = 'RrsForgotYourPasswordEvent';
  satelliteEvent? = 'forgot-password';
}

export const NonGenericPagesIds = [
  'search',
  '/cart',
  'my-account',
  '/order-summary',
  ...RrsCheckoutStepsParams,
];
export const NonGenericPagesSemanticRoutes = ['product'];
