import { Pipe, PipeTransform } from '@angular/core';
import { DONATION_PRODUCT_TYPE } from '@app/custom/features/rrs-donation/models/donation.constant';
import { Product } from '@spartacus/core';

@Pipe({
  name: 'rrsProductDetails',
})
export class RrsProductDetailsPipe implements PipeTransform {
  transform(product: Product): {
    brand?: string;
    colorPrimary?: string;
    colorSecondary?: string;
    size?: string;
    width?: string;
  } {
    if (this.isDonation(product)) {
      return {};
    }
    const brand = product.categories?.find((category) =>
      category.code?.includes('brand')
    )?.name;
    const { colorPrimary, colorSecondary, width } =
      product.baseOptions![0].selected!;
    const size =
      product.baseOptions![0].selected?.sizeconversion ||
      product.baseOptions![0].selected?.size;
    return { brand, colorPrimary, colorSecondary, size, width };
  }

  isDonation(product: Product): boolean {
    return product?.baseProduct === DONATION_PRODUCT_TYPE;
  }
}
