import { ModalService } from '@app/custom/features/modal';
import { RrsAuthService } from '@app/custom/features/rrs-auth/services/rrs-auth.service';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '@app/shared/configs';
import { MONTH_SELECT } from '@app/shared/models';
import { CustomValidators } from '@app/shared/utils';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import {
  AuthService,
  ErrorModel,
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
  User,
} from '@spartacus/core';
import {
  UserPasswordFacade,
  UserProfileFacade,
  UserRegisterFacade,
} from '@spartacus/user/profile/root';
import {
  BehaviorSubject,
  Subscription,
  combineLatest,
  of,
  throwError,
} from 'rxjs';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { isEmailOrPhone, ONLY_NUMBERS } from '../utils/emailOrPhone.validator';
import { RrsEventsDispatcherService } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/services/rrs-events.dispatcher';
import { RrsCustomerGroupsService } from '@app/custom/features/rrs-account/services/rrs-customer-groups.service';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';

enum RrsAuthStep {
  loginEmailOrPhone,
  loginPassword,
  passwordReset,
  register,
  registerOptional,
  registerCompleted,
}

@Component({
  selector: 'rrs-auth',
  templateUrl: './rrs-auth.component.html',
  styleUrls: ['./rrs-auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsAuthComponent implements OnInit, OnDestroy {
  data?: {
    showGuestLogin: boolean;
  };
  icon = ICON_TYPE_LIST;
  isLoading$ = new BehaviorSubject<boolean>(false);
  AUTH_STEPS = RrsAuthStep;
  authStep = RrsAuthStep.loginEmailOrPhone;
  emailPhoneForm!: FormGroup;
  passwordForm!: FormGroup;
  signUpForm!: FormGroup;
  signUpOptionalForm!: FormGroup;
  monthOfBirthOptions = MONTH_SELECT;
  @Input() isAuthPage = false;

  authStepTitles = [
    'rrs.loginForm.signInSignUp',
    'rrs.loginForm.signIn',
    'rrs.loginForm.forgotPassword',
    'rrs.loginForm.signUp',
    'rrs.loginForm.signInOptional',
    'rrs.loginForm.signInCompleted',
  ];

  ICON_TYPE_LIST = ICON_TYPE_LIST;
  monthSelectOpened = false;

  private emailAddedSub: Subscription | undefined;
  subscriptions = new Subscription();

  constructor(
    protected activeCartService: RrsActiveCartService,
    protected authService: AuthService,
    protected formBuilder: FormBuilder,
    protected globalMessage: GlobalMessageService,
    protected modalService: ModalService,
    protected routingService: RoutingService,
    protected rrsAuthService: RrsAuthService,
    protected userPasswordFacade: UserPasswordFacade,
    protected userRegisterFacade: UserRegisterFacade,
    protected eventDispatcher: RrsEventsDispatcherService,
    protected rrsCustomerGroupsService: RrsCustomerGroupsService,
    protected userProfileFacade: UserProfileFacade
  ) {}

  ngOnInit(): void {
    this.emailPhoneForm = this.formBuilder.group({
      emailOrPhone: [
        '',
        Validators.compose([Validators.required, isEmailOrPhone()]),
      ],
    });

    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required])],
    });

    this.signUpForm = this.formBuilder.group({
      firstName: [
        '',
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      lastName: [
        '',
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.lengthRange(
            PASSWORD_MIN_LENGTH,
            PASSWORD_MAX_LENGTH
          ),
          CustomValidators.lowerCase,
          CustomValidators.numeric,
          CustomValidators.specialChars,
          CustomValidators.upperCase,
        ]),
      ],
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });

    this.signUpOptionalForm = this.formBuilder.group({
      phone: ['', CustomValidators.telephone],
      monthOfBirth: [''],
    });

    this.subscriptions.add(
      this.authService
        .isUserLoggedIn()
        .pipe(
          filter(Boolean),
          take(1),
          tap(() => {
            if (this.authStep === RrsAuthStep.loginPassword)
              this.modalService.closeActiveModal();
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    if (this.emailAddedSub) {
      this.emailAddedSub?.unsubscribe();
    }
    this.subscriptions.unsubscribe();
  }

  setStep(step: RrsAuthStep): void {
    this.authStep = step;
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.isLoading$.next(false);
  }

  validateUser(emailOrPhone: string): void {
    this.emailPhoneForm.get('emailOrPhone')?.markAsTouched();

    if (!this.emailPhoneForm.valid) return;

    this.setLoading();

    this.subscriptions.add(
      this.rrsAuthService
        .validateIfUserExists(emailOrPhone)
        .subscribe((isValid) => {
          this.setLoading(false);

          if (isValid) {
            this.setStep(RrsAuthStep.loginPassword);
          } else {
            this.copyEmailOrPhoneToSignUp();
            this.setStep(RrsAuthStep.register);
            this.eventDispatcher.dispatchRegistrationStartEvent();
          }
        })
    );
  }

  copyEmailOrPhoneToSignUp(): void {
    const emailOrPhone = this.emailPhoneForm.get('emailOrPhone')?.value;
    // if its a phone number copy to optional data form
    // otherwise copy to email sign up form
    if (ONLY_NUMBERS.test(emailOrPhone)) {
      this.signUpOptionalForm.get('phone')?.setValue(emailOrPhone);
    } else {
      this.signUpForm.get('email')?.setValue(emailOrPhone);
    }
  }

  setLoading(isLoading = true): void {
    this.isLoading$.next(isLoading);
  }

  doLogin(): void {
    this.passwordForm.get('password')?.markAsTouched();

    if (this.passwordForm.get('password')?.valid) {
      this.setLoading();

      this.rrsAuthService
        .login(
          this.emailPhoneForm.value?.emailOrPhone,
          this.passwordForm.value?.password
        )
        .then((res) => {
          this.setLoading(false);
          this.eventDispatcher.dispatchLoginSuccessEvent();
        })
        .catch((error) => {
          this.setLoading(false);
        });
    }
  }

  closeModal(): void {
    this.modalService.closeActiveModal();
  }

  goToAccountPage(): void {
    this.routingService.go(['/my-account/rewards']);
  }

  goToHomePage(): void {
    this.routingService.go({ cxRoute: 'home' });
  }

  goToLoginPage(): void {
    this.setStep(RrsAuthStep.loginEmailOrPhone);
  }

  resetPassword(): void {
    this.emailPhoneForm.get('emailOrPhone')?.markAsTouched();

    if (!this.emailPhoneForm.valid) return;

    this.setLoading();

    this.subscriptions.add(
      this.userPasswordFacade
        .requestForgotPasswordEmail(
          this.emailPhoneForm.get('emailOrPhone')?.value
        )
        .subscribe({
          next: () => this.onSuccess(),
          error: () => this.isLoading$.next(false),
        })
    );
  }

  doSignUp(): void {
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.signUpForm.markAllAsTouched();

    if (this.signUpForm.get('password')?.invalid) {
      this.globalMessage.add(
        { key: 'signup.incorrectPassword' },
        GlobalMessageType.MSG_TYPE_ERROR
      );
      return;
    }

    const formValues = this.signUpForm.value;

    if (this.signUpForm.valid) {
      this.setLoading();

      this.subscriptions.add(
        this.userRegisterFacade
          .register({
            ...formValues,
            uid: formValues.email,
            customerPhone: '', // has to be empty string to avoid being rejected by BE https://rackroom.atlassian.net/browse/HYB-3521?focusedCommentId=48180
          })
          .pipe(
            catchError((err) => {
              this.setLoading(false);

              // modified to handle signup error structure
              const errorDetails = err?.details[0]?.message;
              this.globalMessage.add(
                errorDetails || err?.message,
                GlobalMessageType.MSG_TYPE_ERROR
              );
              return throwError(err);
            })
          )
          .subscribe(() => {
            this.rrsAuthService
              .loginWithoutRedirect(
                formValues.email,
                this.signUpForm.get('password')?.value
              )
              .then(() => {
                this.setLoading(false);
                this.setStep(RrsAuthStep.registerOptional);
                this.eventDispatcher.dispatchRegistrationSuccessEvent();
              });
          })
      );
    }
  }

  saveOptional(): void {
    let saveGroup$;
    this.signUpOptionalForm.markAllAsTouched();
    if (!this.signUpOptionalForm.valid) return;
    this.setLoading();

    // @TODO: Replace with single request once HYB-3502 is ready
    if (this.signUpOptionalForm.controls['monthOfBirth']?.value) {
      saveGroup$ = this.rrsCustomerGroupsService.addToCustomerGroup(
        this.signUpOptionalForm.controls['monthOfBirth']?.value
      );
    } else {
      saveGroup$ = of(null);
    }
    const savePhone$ = this.userProfileFacade.update({
      customerPhone: this.signUpOptionalForm.controls['phone']?.value,
      firstName: this.signUpForm.controls['firstName']?.value,
      lastName: this.signUpForm.controls['lastName']?.value,
    } as User);

    combineLatest([saveGroup$, savePhone$])
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.setLoading(false);
          this.setStep(RrsAuthStep.registerCompleted);
        },
        error: (error) => {
          error.details.forEach((error: ErrorModel) => {
            if (error.subject === 'customerPhone') {
              this.globalMessage.add(
                { key: 'httpHandlers.validationErrors.missing.customerPhone' },
                GlobalMessageType.MSG_TYPE_ERROR
              );
              return;
            }
            this.globalMessage.add(
              error.message || '',
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
          this.setLoading(false);
        },
      });
  }

  skipOptional(): void {
    this.setStep(RrsAuthStep.registerCompleted);
  }

  protected onSuccess(): void {
    this.globalMessage.add(
      { key: 'rrs.forgottenPassword.passwordResetEmailSent' },
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );
    this.emailPhoneForm.reset();
    this.setStep(RrsAuthStep.loginEmailOrPhone);
    this.isLoading$.next(false);
  }
}
