import { ASM_FEATURE, AsmRootModule } from '@spartacus/asm/root';
import {
  asmTranslationChunksConfig,
  asmTranslations,
} from '@spartacus/asm/assets';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [AsmRootModule],
  providers: [
    provideConfig({
      featureModules: {
        [ASM_FEATURE]: {
          module: () => import('@spartacus/asm').then((m) => m.AsmModule),
        },
      },
    } as CmsConfig),
    provideConfig({
      i18n: {
        resources: asmTranslations,
        chunks: asmTranslationChunksConfig,
      },
    } as I18nConfig),
  ],
})
export class AsmFeatureModule {}
