export const FRESH_CHAT_SCRIPT_SRC = 'https://wchat.freshchat.com/js/widget.js';
export const FRESH_CHAT_TOKEN = '50a246f0-7d43-4e6a-9746-743be4e43825';
export const FRESH_CHAT_HOST = 'https://rackroomshoes.freshchat.com';

export const FRESH_CHAT_CONFIG = {
  headerProperty: {
    backgroundColor: '#FF6400',
    foregroundColor: '#FFFFFF',
    hideChatButton: true,
    fontName: 'Montserrat',
    fontUrl: 'https://fonts.googleapis.com/css?family=Montserrat',
  },
  cssNames: {
    widget: 'custom_fc_frame',
    expanded: 'custom_fc_expanded',
  },
};
