import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalService } from '@app/custom/features/modal';
import { Observable, of } from 'rxjs';
import { Product } from '@spartacus/core';
import { SwiperOptions } from 'swiper';
import { Scene7Presets } from '@app/shared/configs/scene7.config';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-product-details-zoom',
  styleUrls: ['./rrs-product-details-zoom.component.scss'],
  templateUrl: './rrs-product-details-zoom.component.html',
})
export class RrsProductDetailsZoomComponent {
  activeSlide: number = 0;
  scene7Preset = Scene7Presets.ZOOM;

  config: SwiperOptions = {
    breakpoints: {
      992: {
        enabled: true,
      },
    },
    enabled: false,
    loop: true,
    navigation: true,
    slidesPerGroup: 1,
    slidesPerView: 1,
    threshold: 10,
    autoHeight: true,
  };

  product$: Observable<Product | null> = of(null);
  productGallery$: Observable<any[]> | undefined;

  constructor(protected modalService: ModalService) {}

  closeModal(): void {
    this.modalService.closeActiveModal();
  }
}
