import { Injectable, OnDestroy } from '@angular/core';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import {
  CartEventInfo,
  RrsAddToCartEvent,
  RrsAddToCartSuccessEvent,
  RrsAddToWishlistEvent,
  RrsAddToWishlistUiEvent,
  RrsCartAddEntryEvent,
  RrsCartAddEntrySuccessEvent,
  RrsCartRemoveEntrySuccessEvent,
  RrsRemoveEntryFromCartEvent,
  RrsRemoveFromCartEvent,
} from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/model/events';
import {
  CartAddEntrySuccessEvent,
  CartRemoveEntrySuccessEvent,
} from '@spartacus/cart/base/root';
import {
  createFrom,
  EventService,
  LoginEvent,
  RouterState,
  RoutingService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { getCartInfo } from './utils/utils';

@Injectable({
  providedIn: 'root',
})
export class RrsCartEventsBuilder implements OnDestroy {
  constructor(
    protected eventService: EventService,
    protected routingService: RoutingService,
    protected activeCartService: RrsActiveCartService
  ) {
    this.register();
  }

  eventSourceTeardowns = Array<() => void>();

  ngOnDestroy(): void {
    this.eventSourceTeardowns.forEach((unregister) => unregister());
  }

  protected register(): void {
    this.eventSourceTeardowns.push(
      this.eventService.register(RrsAddToCartEvent, this.buildAddToCartEvent())
    );
    this.eventSourceTeardowns.push(
      this.eventService.register(
        RrsRemoveFromCartEvent,
        this.buildRemoveFromCartEvent()
      )
    );
    this.eventSourceTeardowns.push(
      this.eventService.register(
        RrsAddToCartSuccessEvent,
        this.buildAddToCartSuccessEvent()
      )
    );
    this.eventSourceTeardowns.push(
      this.eventService.register(
        RrsCartRemoveEntrySuccessEvent,
        this.buildCartRemoveEntrySuccessEvent()
      )
    );
    this.eventSourceTeardowns.push(
      this.eventService.register(
        RrsAddToWishlistEvent,
        this.buildAddToWishlitEvent()
      )
    );
  }

  protected buildAddToCartEvent(): Observable<RrsAddToCartEvent> {
    return this.eventService.get(RrsCartAddEntryEvent).pipe(
      withLatestFrom(this.routingService.getRouterState()),
      map(([event, routingState]) => {
        return createFrom(RrsAddToCartEvent, {
          customEvent: new CustomEvent('rrsstorefront.addToCart'),
          cart: event.cart,
          event: [
            {
              eventInfo: {
                eventName: 'add to bag',
                eventAction: 'scAdd,scOpen',
                eventPage: this.getPageNameFromRoutingState(routingState),
              },
              productInfo: {
                addToCartLocaton:
                  this.getPageNameFromRoutingState(routingState),
                sku: event.productCode || '',
                cart_id: event.cartId || '',
                quantity: event.quantity,
                price: event.price,
              },
            },
          ],
        });
      })
    );
  }

  protected buildAddToCartSuccessEvent(): Observable<RrsAddToCartSuccessEvent> {
    return this.eventService.get(RrsCartAddEntrySuccessEvent).pipe(
      withLatestFrom(this.activeCartService.takeActive()),
      map(([event, cart]) => {
        return createFrom(RrsAddToCartSuccessEvent, {
          cart: getCartInfo(cart),
          event: [],
        });
      })
    );
  }

  protected buildRemoveFromCartEvent(): Observable<RrsRemoveFromCartEvent> {
    return this.eventService.get(RrsRemoveEntryFromCartEvent).pipe(
      withLatestFrom(
        this.routingService.getRouterState(),
        this.activeCartService.getActiveCartId()
      ),
      map(([event, routingState, cartId]) => {
        return createFrom(RrsRemoveFromCartEvent, {
          event: [
            {
              eventInfo: {
                eventName: 'remove from cart',
                eventAction: 'scRemove',
                eventPage: this.getPageNameFromRoutingState(routingState),
              },
              productInfo: {
                sku: event.entry.product?.baseProduct || 'unknown',
                cart_id: cartId || 'unknown',
              },
            },
          ],
        });
      })
    );
  }

  protected buildCartRemoveEntrySuccessEvent(): Observable<RrsCartRemoveEntrySuccessEvent> {
    return this.eventService.get(CartRemoveEntrySuccessEvent).pipe(
      withLatestFrom(this.activeCartService.takeActive()),
      map(([event, cart]) => {
        return createFrom(RrsCartRemoveEntrySuccessEvent, {
          entry: event.entry,
          // TODO: This cart still contains the removed entry.
          cart: getCartInfo(cart),
        });
      })
    );
  }

  protected buildAddToWishlitEvent(): Observable<RrsAddToWishlistEvent> {
    return this.eventService.get(RrsAddToWishlistUiEvent).pipe(
      withLatestFrom(this.routingService.getRouterState()),
      map(([event, routingState]) =>
        createFrom(RrsAddToWishlistEvent, {
          event: [
            {
              eventInfo: {
                eventName: 'add to wishlist',
                eventAction: '',
                eventPage: this.getPageNameFromRoutingState(routingState),
              },
              cart: {
                cart_id: event.cartId,
              },
              productInfo: {
                sku: event.product?.baseProduct || 'unknown',
                sizeSku: event.product?.code || 'unknown',
                cart_id: event.cartId,
              },
            },
          ],
        })
      )
    );
  }

  private getPageNameFromRoutingState(routingState: RouterState): string {
    if (routingState.state?.semanticRoute === 'product')
      return 'product details page';

    if (routingState.state?.context?.id === '/cart') return 'cart summary';

    return '';
  }
}
