<section class="product-details py-4">
  <ng-container *ngIf="(isLoading$ | async) === false; else pageLoader">
    <ng-container *ngIf="product$ | async as product">
      <div class="container">
        <div class="row gx-0 gx-lg-5">
          <div class="col-12 col-lg-6">
            <rrs-product-details-images></rrs-product-details-images>
          </div>
          <div class="col-12 col-lg-6">
            <div class="mb-4">
              <ng-container *ngIf="!isDonation">
                <rrs-product-category></rrs-product-category>
              </ng-container>

              <ng-container *ngIf="{ heading: seoHeading$ | async } as seo">
                <h1
                  *ngIf="seo.heading || product?.name"
                  data-cy="product-title"
                  class="fw-normal fs-2">
                  {{ seo.heading || product.name }}
                </h1>
                <a *ngFor="let badge of badges" class="d-flex">
                  <span
                    [style.background-color]="badge.bgColor"
                    [style.color]="badge.textColor"
                    [style.border]="badge.border"
                    class="badge"
                    >{{ badge.label }}</span
                  >
                </a>
              </ng-container>

              <ng-container *ngIf="!isDonation">
                <div class="row row-cols-1 row-cols-lg-2">
                  <div
                    *ngIf="product.price?.isVisible === true; else priceHidden">
                    <p
                      class="text-success fw-bold fs-3"
                      data-cy="product-price">
                      {{ product.price?.formattedValue }}
                      <span
                        *ngIf="product.price?.comparePrice"
                        class="fs-6 fw-normal text-light text-decoration-line-through ms-3">
                        ${{ product.price?.comparePrice }}</span
                      >
                    </p>
                    <klarna-placement
                      class="d-block mb-3"
                      #klarnaBanner
                      data-key="credit-promotion-badge"
                      data-locale="en-US"
                      [attr.data-purchase-amount]="
                        getKlarnaPurchaseAmount(product.price.value)
                      "></klarna-placement>
                  </div>
                  <ng-template #priceHidden>
                    <p>
                      {{ 'rrs.product.SeePriceInCart' | cxTranslate }}
                    </p>
                  </ng-template>
                  <div id="pr-reviewsnippet"></div>
                </div>
              </ng-container>
            </div>

            <ng-container *ngIf="!isDonation; else donationPorduct">
              <rrs-product-variants-container class="mb-4">
              </rrs-product-variants-container>
            </ng-container>

            <ng-template #donationPorduct>
              <rrs-donation-variant [currentProduct]="product">
              </rrs-donation-variant>
            </ng-template>

            <cx-page-slot class="d-block my-3" position="ProductDetailsRight">
            </cx-page-slot>
          </div>

          <div class="row">
            <cx-page-slot position="pre_description_content"></cx-page-slot>

            <rrs-product-description
              [product]="product"></rrs-product-description>

            <cx-page-slot position="post_description_content"></cx-page-slot>
          </div>

          <ng-container *ngIf="!isDonation">
            <section class="pr-reviews" id="power-reviews">
              <header class="pr-reviews__header my-4">
                <h2 class="fs-7 m-0 d-flex">Reviews</h2>
              </header>
            </section>

            <div #powerreviews>
              <div id="pr-imagesnippet"></div>
              <div id="pr-reviewdisplay"></div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #pageLoader>
    <div class="d-flex align-items-center justify-content-center p-5">
      <div
        class="spinner-border"
        role="status"
        style="width: 6rem; height: 6rem">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-template>
</section>
