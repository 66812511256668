import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import { DONATION_PRODUCT_TYPE } from '@app/custom/features/rrs-donation/models/donation.constant';
import { RrsRemoveEntryFromCartEvent } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/model/events';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderEntry } from '@spartacus/cart/base/root';
import { CmsService, EventService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'rrs-mini-product-card',
  templateUrl: './rrs-mini-product-card.component.html',
  styleUrls: ['./rrs-mini-product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsMiniProductCardComponent {
  @Input() entry!: OrderEntry;

  icon = ICON_TYPE_LIST.CLOSE_CIRCLE;

  subscriptions = new Subscription();

  constructor(
    private activeCartService: RrsActiveCartService,
    private modalService: NgbModal,
    private cmsService: CmsService,
    private eventService: EventService
  ) {}

  get isDonation(): boolean {
    return this.entry.product?.baseProduct === DONATION_PRODUCT_TYPE;
  }

  removeCartEntry(entry: OrderEntry): void {
    this.activeCartService.removeEntry(entry);
    this.modalService.dismissAll();

    this.subscriptions.add(
      this.activeCartService.getActive().subscribe((cart) => {
        if (!cart.totalItems) {
          this.cmsService.refreshLatestPage();
        }
        this.eventService.dispatch(
          {
            entry,
            cart,
          },
          RrsRemoveEntryFromCartEvent
        );
      })
    );
  }
}
