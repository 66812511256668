import {
  FRESH_CHAT_HOST,
  FRESH_CHAT_SCRIPT_SRC,
  FRESH_CHAT_TOKEN,
} from '@app/custom/features/rrs-freshchat/rrs-freshchat.config';
import { Inject, Injectable } from '@angular/core';
import { UserIdService, WindowRef } from '@spartacus/core';
import { take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { RrsEventsDispatcherService } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/services/rrs-events.dispatcher';
import { environment } from '@environments/environment';

declare global {
  interface Window {
    fcSettings: any;
    fcWidget: any;
    openHelp: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class RrsFreshChatService {
  nativeWindow = this.winRef.nativeWindow as Window;

  constructor(
    private userIdService: UserIdService,
    protected winRef: WindowRef,
    @Inject(DOCUMENT) private document: Document,
    protected eventsDispatcherService: RrsEventsDispatcherService
  ) {}

  initFreshChat(): void {
    const freshchatWidgetScript = this.document.createElement('script');
    freshchatWidgetScript.async = true;
    freshchatWidgetScript.src = FRESH_CHAT_SCRIPT_SRC;
    freshchatWidgetScript.onload = (): void => {
      this.userIdService
        .getUserId()
        .pipe(take(1))
        .subscribe((id) => {
          this.nativeWindow.fcWidget.init({
            config: {
              headerProperty: {
                hideChatButton: true,
              },
            },
            token: FRESH_CHAT_TOKEN,
            host: FRESH_CHAT_HOST,
            widgetUuid: environment.freshChat.widgetUuid,
            externalId: id ? id : '',
          });
          this.nativeWindow.openHelp = (): void => this.open();
        });
    };
    this.document.head.appendChild(freshchatWidgetScript);
  }

  private getWidget(): any {
    return this.nativeWindow.fcWidget;
  }

  open(): void {
    this.eventsDispatcherService.dispatchLiveChatEvent();
    // .show() toggles visibility
    this.getWidget().show();
    // .open() maximizes the chat widget to it's full size
    this.getWidget().open();
  }

  close(): void {
    // .hide() toggles visibility
    this.getWidget().hide();
    // .close() minimizes the widget to the closed state, which
    // is the small button in the lower right of the screen by default
    // which we hid with .hide()
    this.getWidget().close();
  }
}
