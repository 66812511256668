import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { DONATION_PRODUCT_TYPE } from '@app/custom/features/rrs-donation/models/donation.constant';
import { RrsRemoveEntryFromCartEvent } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/model/events';
import { Badge } from '@app/shared/configs';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { rrsPromotion } from '@app/shared/models';
import { getBadges } from '@app/shared/utils';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import {
  CartItemComponent,
  CartItemContextSource,
} from '@spartacus/cart/base/components';
import { CartItemContext } from '@spartacus/cart/base/root';
import { Address, CmsService, EventService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { map, pluck, take } from 'rxjs/operators';
import { RrsActiveCartService } from '../../services/rrs-active-cart.service';

@AutoUnsubscribe()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CartItemContextSource,
    { provide: CartItemContext, useExisting: CartItemContextSource },
  ],
  selector: 'rrs-cart-item',
  styleUrls: ['./rrs-cart-item.component.scss'],
  templateUrl: './rrs-cart-item.component.html',
})
export class RrsCartItemComponent extends CartItemComponent implements OnInit {
  iconTypeList = ICON_TYPE_LIST;
  badges: Array<Badge> = [];

  @Input() deliveryAddress?: Address;

  @Input() cartListLength: number = 0;

  @Input() options = {
    isSaveForLater: false,
    optionalBtn: null,
    isSavedCart: false,
  };

  @Input() productPromotions?: rrsPromotion[];

  private subscriptions = new Subscription();

  constructor(
    cartItemContextSource: CartItemContextSource,
    protected router: Router,
    protected eventService: EventService,
    protected cmsService: CmsService,
    protected activeCartService: RrsActiveCartService
  ) {
    super(cartItemContextSource);
  }

  get isDonation(): boolean {
    return this.item.product?.baseProduct === DONATION_PRODUCT_TYPE;
  }

  removeItem(): void {
    this.subscriptions.add(
      this.activeCartService.getActive().subscribe((cart) => {
        if (!cart.totalItems) {
          this.cmsService.refreshLatestPage();
        }
        this.eventService.dispatch(
          {
            entry: this.item,
            cart,
          },
          RrsRemoveEntryFromCartEvent
        );
        this.quantityControl.setValue(0);
        this.quantityControl.markAsDirty();
      })
    );
  }

  ngOnInit(): void {
    if (this.item.product?.categories) {
      this.badges = getBadges(this.item.product?.categories);
    }
  }
}
