import { Component, inject } from '@angular/core';
import { AsmService } from '@spartacus/asm/core';
import { AsmEnablerService } from '@spartacus/asm/root';
import { StorefrontComponent } from '@spartacus/storefront';
import { map } from 'rxjs/operators';

@Component({
  selector: 'rrs-storefront',
  styleUrls: ['./rrs-storefront.component.scss'],
  templateUrl: './rrs-storefront.component.html',
})
export class RrsStorefrontComponent extends StorefrontComponent {
  private asmEnablerService: AsmEnablerService = inject(AsmEnablerService);
  private asmService: AsmService = inject(AsmService);

  isAsmEnabled = this.asmEnablerService.isEnabled();
  isAsmOpened$ = this.asmService
    .getAsmUiState()
    .pipe(map((asmUi) => !asmUi.collapsed));
}
